import React, { useEffect, useState } from "react";
import docData from "../../data/Doctors/all-doctors";
import GetDoctor from "../../components/Doctors/GetDoctor";
import docOrder from "../../data/Doctors/doc-order.json";
const GetDoctors = ({ dept, hospitals = "" }) => {
  const [doctorList, setDoctorList] = useState([]);

  useEffect(() => {
    async function filterDoctor() {
      let docList = await docData
        .filter((doc) => doc.dept === dept)
        .filter((doc) => {
          //  IF HOSPITALS IS EMPTY, FIND ALL DOCTORS IN ALL HOSPITALS
          if (hospitals === "") {
            let { sameDept = false } = doc;

            if (!sameDept) {
              return doc;
            }
          }
          // console.log(doc);
          let { hospitalArr = [] } = doc;
          if (hospitalArr.length > 0 && hospitalArr.includes(hospitals)) {
            return doc;
          } else if (doc.hospitals === hospitals) {
            return doc;
          }
        });

      // Filter order based on branch and department
      const selectedOrderData = docOrder.find(
        (item) => item.hospitals === hospitals && item.dept === dept
      );

      if (selectedOrderData) {
        const sortedDoctors = docList
          .filter((doc) => selectedOrderData.order.includes(doc.id)) // Filter doctors matching the order list
          .sort(
            (a, b) =>
              selectedOrderData.order.indexOf(a.id) -
              selectedOrderData.order.indexOf(b.id)
          );
        // Sort based on the order array
        setDoctorList(sortedDoctors);
      } else {
        setDoctorList(docList);
      }
    }

    filterDoctor();
  }, []);

  return (
    <div className="team-page-area-wrapper ">
      <div className="container">
        <div className="row mtn-30" style={{ paddingBottom: "60px" }}>
          {doctorList.map((doc) => (
            <GetDoctor key={doc.id} type={"page"} doctor={doc} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GetDoctors;
