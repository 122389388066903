import React, { Fragment } from "react";
import { Link } from "react-scroll";

const DeptList = ({ onSetActiveFunc }) => {
  const [deptList, setDeptList] = React.useState([
    {
      id: 2,
      dept: "paediatrics_and_subspeciatilies",
      deptTitle: "Paediatrics and Subspecialities",
    },
    {
      id: 3,
      deptTitle: "Developmental Pediatrics",
      dept: "developmental_pediatrics",
    },
    {
      id: 4,
      deptTitle: "Anaesthesia and Critical Care",
      dept: "anaesthesia_and_critical_care",
    },

    {
      id: 10,
      deptTitle: "Cosmetologist and Plastic Surgery",
      dept: "cosmetologists_and_plastic_surgeons",
    },

    {
      id: 12,
      deptTitle: "Other Specialities",
      dept: "other_specialities",
    },
  ]);

  return (
    <Fragment>
      <br />
      <div className="container">
        <div className="row deptListDiv">
          {deptList.map((deptData) => (
            <div key={deptData.id} className="col-sm-4 cardComp">
              <Link
                activeclassname="active"
                to={deptData.dept}
                spy={true}
                smooth={true}
                duration={1000}
                delay={500}
                offset={-300}
                onSetActive={onSetActiveFunc}
              >
                <div className="card cardStyle">
                  <div className="card-body">
                    <h5 className="card-title cardTitle">
                      {deptData.deptTitle}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <br />
    </Fragment>
  );
};

export default DeptList;
