const RedirectinalArray = [
  {
    link: ["/appointment-booking?city=kangarooclinics"],
    redirect: "/book-appointment",
  },
  {
    link: [
      "/services-Child-Development-Clinic.html?utm_source=Social&utm_medium=Youtube&utm_campaign=rtad2908",
      "/services-Child-Development-Clinic.html",
    ],
    redirect: "/specialities/child-development-clinic-bangalore/8",
  },
  {
    link: ["/career.html"],
    redirect: "/careers",
  },
  {
    link: ["/events-maternity-hospital-bangalore.html"],
    redirect: "/specialities/maternity-hospital-bangalore/7",
  },
  {
    link: ["/appointment/shruthi-s-h?id=155&appointmentLink"],
    redirect: "/book-appointment",
  },
  {
    link: ["/specialities/child-development-clinic-bangalore/83"],
    redirect: "/specialities/child-development-clinic-bangalore/8",
  },
  {
    link: ["/specialities/gynaecology-hospital-bangalore/"],
    redirect: "/specialities/gynaecology-hospital-bangalore/6",
  },
  {
    link: ["/specialities/child-development-clinic-bangalore/88"],
    redirect: "/specialities/child-development-clinic-bangalore/8",
  },
  {
    link: ["/KATS.html"],
    redirect: "/kats",
  },
  {
    link: ["/doctor-Physicians-vijayanagar.html"],
    redirect: "/doctors/bangalore",
  },
  {
    link: ["/Cosmetologists-and-Plastic-Surgeons.html"],
    redirect: "/doctors/bangalore",
  },
  {
    link: ["/centre"],
    redirect: "/centre/bangalore",
  },
  {
    link: ["/services-NICU-hospital-near-vijayanagar.html"],
    redirect: "/specialities/neonatal-intensive-care-unit-bangalore/4",
  },
  {
    link: ["/services-NICU-hospital-near-vijayanagar.html"],
    redirect: "/specialities/neonatal-intensive-care-unit-bangalore/4",
  },
  {
    link: ["/services-paediatric-hospital-near-vijayanagar.html"],
    redirect: "/specialities/paediatric-hdu-bangalore/5",
  },
  {
    link: ["/services-paediatric-hospital-near-vijayanagar.html"],
    redirect: "/specialities/paediatric-hdu-bangalore/5",
  },
  {
    link: ["/doctor/naveen-k-s?id=14"],
    redirect: "/doctors/bangalore",
  },
  {
    link: [
      "/appointment/satish-babu?id=27&appointmentLink=dr-sathish-babu-bangalore-endocrinology&eid=36433&hideHeader=true",
    ],
    redirect: "/book-appointment",
  },
  {
    link: ["/appointment"],
    redirect: "/book-appointment",
  },
  {
    link: ["/doctor/santhosh-olety-sathyanarayana?id=22"],
    redirect: "/doctors",
  },
  {
    link: ["/Doctors-Anaesthesia-and-Critical-Care.html"],
    redirect: "/doctors",
  },
  {
    link: ["/Doctors-Infertility-and-IVF.html"],
    redirect: "/doctors/fertility-center-bengaluru",
  },
  {
    link: ["/Downloads.html"],
    redirect: "/media/downloads",
  },
  {
    link: ["/Doctors-Specialist-for-mind-care.html"],
    redirect: "/doctors",
  },
  {
    link: ["/Doctors-Dietitians.html"],
    redirect: "/doctors",
  },
  {
    link: ["/files/"],
    redirect: "/",
  },
  {
    link: ["/boo.html"],
    redirect: "/",
  },
  {
    link: ["/boo.html"],
    redirect: "/",
  },
  {
    link: ["/doctor/b-n-satyanarayana-rao/41", "/doctor/bharathi-ramesh/41"],
    redirect: "/doctors",
  },
  {
    link: ["/Doctors-Specialist-for-mind-care.html"],
    redirect: "/doctors",
  },
  {
    link: ["/contact-us-kangaroocare-vijayanagar.html"],
    redirect: "/centre/bangalore",
  },
  {
    link: ["/contact-us-kangaroocare-vijayanagar.html"],
    redirect: "/centre/bangalore",
  },
  {
    link: ["/services-Paediatric-HDU.html"],
    redirect: "/specialities/paediatric-hdu-bangalore/5",
  },
  {
    link: ["/services-Paediatric-HDU.html"],
    redirect: "/specialities/paediatric-hdu-bangalore/5",
  },
  {
    link: [
      "/appointment/nitin-nayak?id=91&appointmentLink=dr-nithin-nayak-bangalore-paediatric-nephrology&eid=36433",
    ],
    redirect: "/book-appointment",
  },
  {
    link: ["/doctor/santhosh-olety-sathyanarayana/22"],
    redirect: "/doctors",
  },
  {
    link: ["/index.html"],
    redirect: "/",
  },
  {
    link: ["/gallery.html"],
    redirect: "/",
  },
  {
    link: ["/admin-team.html"],
    redirect: "/about/admin-team",
  },
  {
    link: ["/whatsapp-widget.min.js?_"],
    redirect: "/",
  },
  {
    link: ["/blog.html"],
    redirect: "/blog",
  },
  {
    link: ["/board.html"],
    redirect: "/",
  },
  {
    link: ["/services-fertility.html"],
    redirect: "/",
  },
  {
    link: ["/career.html"],
    redirect: "/careers",
  },
  {
    link: ["/events-maternity-hospital-bangalore.html"],
    redirect: "/specialities/maternity-hospital-bangalore/7",
  },
  {
    link: ["/maternity-hospital-bangalore-doctor.html"],
    redirect: "/specialities/maternity-hospital-bangalore/7",
  },
  {
    link: ["/Doctors-Endocrinology-And-Diabetology.html"],
    redirect: "/",
  },
  {
    link: ["/404"],
    redirect: "/",
  },
  {
    link: ["/services-Child-Development-Clinic.html"],
    redirect: "/specialities/child-development-clinic-bangalore/8",
  },
  {
    link: ["/policy"],
    redirect: "/policy/privacy_policy",
  },
  {
    link: ["/board.html"],
    redirect: "/",
  },
  {
    link: ["/faq.html"],
    redirect: "/media/faqs",
  },
  {
    link: ["/doctor-surgical-specialist-basaweshwaranagara.html"],
    redirect: "/",
  },
  {
    link: ["/services-maternity-women-and-child-care-hospital.html"],
    redirect: "/",
  },
  {
    link: ["/services-maternity-women-and-child-care-hospital.html"],
    redirect: "/",
  },
  {
    link: ["/services-maternity-women-and-child-care-hospital.html"],
    redirect: "/",
  },
  {
    link: ["/about.html"],
    redirect: "/about",
  },
  {
    link: ["/files/"],
    redirect: "/",
  },
  {
    link: ["/services-fetal-medicine-materinity-hospital-nagarabavi.html"],
    redirect: "/specialities/fetal-medicine-specialist-bangalore/2",
  },
  {
    link: ["/maternity-hospital-bangalore-doctor.html"],
    redirect: "/specialities/maternity-hospital-bangalore/7",
  },
  {
    link: ["/maternity-hospital-bangalore-doctor.html"],
    redirect: "/specialities/maternity-hospital-bangalore/7",
  },
  {
    link: ["/services-fetal-medicine-materinity-hospital-nagarabavi.html"],
    redirect: "/specialities/fetal-medicine-specialist-bangalore/2",
  },
  {
    link: ["/specialities/fertility-ivf-hospital-bangalore/"],
    redirect: "/specialities/fertility-ivf-hospital-bangalore/1",
  },
  {
    link: ["/gallery.html"],
    redirect: "/gallery",
  },
  {
    link: ["/services-OTHER-FACILITIES.html"],
    redirect: "/specialities/other-facilities/9",
  },
  {
    link: ["/service/fetal-medicine-&-maternal-care/2"],
    redirect: "/specialities/fetal-medicine-specialist-bangalore/2",
  },
  {
    link: ["/sitemap.html"],
    redirect: "/sitemap.xml",
  },
  {
    link: ["/specialities/gynaecology-hospital-bangalore"],
    redirect: "/specialities/maternity-hospital-bangalore/7",
  },
  {
    link: [
      "/appointment/nitin-nayak?id=91&appointmentLink=dr-nithin-nayak-bangalore-paediatric-nephrology&eid=36433",
    ],
    redirect: "/book-appointment",
  },
  {
    link: ["/appointment/geetha--t-devaragudi-?id=142&appointmentLink="],
    redirect: "/book-appointment",
  },
  {
    link: ["/appointment/kavita-komandur?id=127&appointmentLink="],
    redirect: "/book-appointment",
  },
  {
    link: ["/appointment/chandan-?id=43&appointmentLink="],
    redirect: "/book-appointment",
  },
  {
    link: ["/appointment/geetha-eashwar-pillai?id=124&appointmentLink="],
    redirect: "/book-appointment",
  },
  {
    link: [
      "/appointment/satish-babu?id=27&appointmentLink=dr-sathish-babu-bangalore-endocrinology&eid=36433&hideHeader=true",
    ],
    redirect: "/book-appointment",
  },
  {
    link: ["/appointment/saraswati-viswanathan?id=45&appointmentLink="],
    redirect: "/book-appointment",
  },

  {
    link: ["/service/best-gynaecology-clinic-in-bangalore/6"],
    redirect: "/specialities/gynaecology-hospital-bangalore/6",
  },
  {
    link: ["/service/best-nicu-hospital-in-bangalore/4"],
    redirect: "/specialities/neonatal-intensive-care-unit-bangalore/4",
  },
  {
    link: ["/service/child-development-clinic/8"],
    redirect: "/specialities/child-development-clinic-bangalore/8",
  },
  {
    link: ["/service/fertility-treatment-in-bangalore/1"],
    redirect: "/specialities/fertility-ivf-hospital-bangalore/1",
  },
  {
    link: ["/service/infant-care-&amp;-paediatrics-hospital-in-bangalore/3"],
    redirect: "/specialities/paediatric-hdu-bangalore/5",
  },
  {
    link: ["/service/other-facilities/9"],
    redirect: "/specialities/other-facilities/9",
  },
  {
    link: ["/service/paediatric-hdu/5"],
    redirect: "/specialities/paediatric-hdu-bangalore/5",
  },
  {
    link: ["/service/top-maternity-hospital-in-bangalore/7"],
    redirect: "/specialities/maternity-hospital-bangalore/7",
  },
  {
    link: ["/doctor/anjali-b-p/164"],
    redirect: "/doctor/anjali-b-p/163",
  },
  {
    link: ["/doctor/chethana-s-g/66"],
    redirect: "/doctor/chethana-s-g/77",
  },
  {
    link: ["/doctor/shreyas-alva/70"],
    redirect: "/doctor/shreyas-alva/76",
  },
  {
    link: ["/policy/fertility-center-mysore"],
    redirect: "/policy/privacy_policy",
  },

  {
    link: ["/gallery/fertility-center-mysore"],
    redirect: "/gallery",
  },
  {
    link: ["/media/fertility-center-mysore"],
    redirect: "/blog",
  },
  {
    link: ["/about/fertility-center-mysore"],
    redirect: "/about",
  },
  {
    link: ["/doctors/clinics/fertility-center-mysore"],
    redirect: "/doctors/fertility-center-mysore",
  },
  {
    link: ["/doctors/bangalore/fertility-center-mysore"],
    redirect: "/doctors/fertility-center-mysore",
  },
  {
    link: ["/centre/fertility-center-ramanagar"],
    redirect: "/centre/kangaroo-care-ramanagara",
  },
  {
    link: ["/fertility-center-mysore"],
    redirect: "/centre/fertility-center-mysore",
  },
  {
    link: ["/files/fertility-center-mysore"],
    redirect: "/centre/fertility-center-mysore",
  },
  {
    link: ["/gallery/fertility-center-mysore"],
    redirect: "/gallery",
  },
  {
    link: ["/policy/Terms_&amp;_Conditions"],
    redirect: "/policy/terms-and-conditions",
  },
  {
    link: ["/policy/Terms_&_Conditions"],
    redirect: "/policy/terms-and-conditions",
  },
  {
    link: ["/policy/fertility-center-mysore"],
    redirect: "/policy/privacy-policy",
  },
  {
    link: ["/policy/privacy_policy"],
    redirect: "/policy/privacy-policy",
  },
  {
    link: ["/appointment-booking?city=kangarooclinics"],
    redirect: "/book-appointment",
  },
  {
    link: ["/appointment-booking?city=fertilitybangaluru"],
    redirect: "/book-appointment",
  },
  {
    link: ["/media/package-details"],
    redirect: "/packages",
  },
  {
    link: ["/services-gynaecology-hospital-near-Basaveshwaranagar.html"],
    redirect: "/specialities/gynaecology-hospital-bangalore/6",
  },
  {
    link: ["/Doctors-Pediatrics-and-SubSpecialties.html"],
    redirect: "/doctors/bangalore/paediatrician-neonatologist-in-bangalore",
  },
  {
    link: ["/appointment-booking?city=mysore"],
    redirect: "/book-appointment/mysore",
  },
  {
    link: ["/appointment-booking?city=kangaroodentistry"],
    redirect: "/book-appointment/dentistry",
  },
  {
    link: ["/appointment-booking?city=bangalore"],
    redirect: "/book-appointment/bengaluru",
  },
  {
    link: ["/service/cosmetic--gynecology/11"],
    redirect: "/specialities/cosmetic-gynecology/11",
  },
  {
    link: ["/service/lactation-&amp;-home-services/10"],
    redirect: "/specialities/lactation-services/10",
  },
  {
    link: ["/bangalore"],
    redirect: "/centre/bangalore",
  },
  {
    link: ["/whatsapp-widget.min.js?_"],
    redirect: "/",
  },
  {
    link: ["/appointment-booking?city=fertilitybangaluru"],
    redirect: "/centre/bangalore",
  },
  {
    link: ["/fertility-ivf-specialist-in-bangalore"],
    redirect: "/specialities/fertility-ivf-hospital-bangalore/1",
  },
  {
    link: ["/appointment-booking?city=bangalore"],
    redirect: "/centre/bangalore",
  },
  {
    link: ["/appointment-booking?city=mysore"],
    redirect: "/centre/mysore",
  },
  {
    link: ["/fertility-ivf-hospital-bangalore"],
    redirect: "/specialities/fertility-ivf-hospital-bangalore/1",
  },
  {
    link: ["/1"],
    redirect: "/",
  },
  {
    link: ["/50"],
    redirect: "/",
  },
  {
    link: ["/aathira-ravindranath"],
    redirect: "/doctors",
  },
  {
    link: ["/appointment-booking?city=kangaroodentistry"],
    redirect: "/doctors/dentistry",
  },
  {
    link: ["/obstetrician-gynaecologist-in-bangalore"],
    redirect: "/specialities/gynaecology-hospital-bangalore/6",
  },
  {
    link: ["/media/training"],
    redirect: "/",
  },
];

export default RedirectinalArray;
