import React from "react";
import VideoTestList from "../../data/Testimonials/galleryVideo.json";
import CustomerTestimonal from "../../data/Testimonials/customerTestimonal.json";

const VideoTestListPage = ({ page }) => {
  let itemList = VideoTestList;
  if (page == 2) {
    itemList = CustomerTestimonal;
  }
  const blogSchemas29 = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Childhood Obesity & Nutrition | Childhood Obesity Treatment | Dr. Manjunatha | Kangaroo Care.",
    "description": "Dr. Manjunatha, Consultant - Paediatrician, Medical Director at Kangaroo Care - Women and Children Hospital, Mysore is here to talk and address Childhood Obesity and Nutrition - Causes, Symptoms and Treatment.",
    "thumbnailUrl": "https://i9.ytimg.com/vi_webp/ZKtMp2HZ1Uo/mqdefault.webp",
    "uploadDate": "2021-11-27",
    "duration": "PT3M15S",  
    "contentUrl": "https://youtu.be/ZKtMp2HZ1Uo",
    "embedUrl": "https://www.youtube.com/embed/ZKtMp2HZ1Uo"
  }
  </script>
  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Febrile Convulsions in Children | Causes & Symptoms of Febrile | Kangaroo Care",
  "description": "Dr Manjunatha, Consultant - Paediatrician, Medical Director at Kangaroo Care - Women and Children Hospital, Mysore is here to address Febrile convulsions in children, their causes, symptoms and treatment.",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/4s6SNInbRdY/mqdefault.webp",
  "uploadDate": "2021-11-27",
  "duration": "PT5M23S",  
  "contentUrl": "https://youtu.be/4s6SNInbRdY",
  "embedUrl": "https://www.youtube.com/embed/4s6SNInbRdY"
}
</script>

<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Occupational Therapy | Newborn Care | Kangaroo Care - Women & Children Hospitals",
  "description": "Ms. Sashikala Ramnath, Occupational Therapist, Co-ordinator Putta Hejje, Early Intervention Program, Ashakiran Hospital, Mysore was here with us at Kangaroo Care - Women & Children Hospitals, Mysore. We are thankful to her for sharing knowledge with parents and families on how to take care of premature babies.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/rmi6cx6QFWI/mqdefault.jpg",
  "uploadDate": "2021-11-29",
  "duration": "PT11M11S",  
  "contentUrl": "https://youtu.be/rmi6cx6QFWI",
  "embedUrl": "https://www.youtube.com/embed/rmi6cx6QFWI"
}
</script>

<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Neonatal Sepsis | Newborn Blood Infection | Kangaroo Care - Women & Children Hospitals",
  "description": "Dr. Nanditha, Consultant - Neonatologist at Kangaroo Care - Women and Children Hospital, Mysore is here to address and talk about Neonatal Sepsis and the causes, symptoms, and treatment of Newborn Sepsis.",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/avZlKb9efFE/mqdefault.webp",
  "uploadDate": "2021-11-22",
  "duration": "PT4M37S",  
  "contentUrl": "https://youtu.be/avZlKb9efFE",
  "embedUrl": "https://www.youtube.com/embed/avZlKb9efFE"
}
</script>


<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Neonatal Intensive Care Unit | NICU | Neonatal Care | Kangaroo Care - Women & Children Hospital",
  "description": "Dr. Nanditha, Consultant - Neonatologist at Kangaroo Care - Women and Children Hospital, Mysore is here to address and talk about the Neonatal Intensive Care Unit (NICU)",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/TLXMK4cqqic/mqdefault.webp",
  "uploadDate": "2021-11-22",
  "duration": "PT6M34S",  
  "contentUrl": "https://youtu.be/TLXMK4cqqic",
  "embedUrl": "https://www.youtube.com/embed/TLXMK4cqqic"
}
</script>
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Newborn Jaundice | Causes, Symptoms and Treatment | Kangaroo Care",
  "description": "Here we have Dr. Nanditha, Consultant - Neonatologist at Kangaroo Care - Women and Children Hospitals, Mysore to put light on newborn jaundice, the cause, symptoms, and treatment of jaundice.",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/BbvYON8D4_o/mqdefault.webp",
  "uploadDate": "2021-11-22",
  "duration": "PT5M20S",  
  "contentUrl": "https://youtu.be/BbvYON8D4_o",
  "embedUrl": "https://www.youtube.com/embed/BbvYON8D4_o"
}
</script>

<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "High-Risk Pregnancy | Pregnancy Care | High-Risk Pregnancy Symptoms | Kangaroo Care",
  "description": "Dr. Veena Keerthi, Consultant - Obstetrician, and Gynecologist at Kangaroo Care - Women & Children Hospital, Mysore is here to address and talk about high-risk pregnancies and what is a high-risk pregnancy? Causes of high-risk pregnancy",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/MdkNVgC_f9g/mqdefault.webp",
  "uploadDate": "2021-11-19",
  "duration": "PT4M33S",  
  "contentUrl": "https://youtu.be/MdkNVgC_f9g",
  "embedUrl": "https://www.youtube.com/embed/MdkNVgC_f9g"
}
</script>
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Infertility | Causes of Infertility | Infertility Treatment | Kangaroo Care",
  "description": "Dr. Veena Keerthi, Consultant - Obstetrician, and Gynecologist at Kangaroo Care - Women & Children Hospital, Mysore is here to let you know more about Infertility, what is Infertility? And What are the signs and symptoms of infertility?",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/XtNb-J37RnU/mqdefault.webp",
  "uploadDate": "2021-11-19",
  "duration": "PT6M2S",  
  "contentUrl": "https://youtu.be/XtNb-J37RnU",
  "embedUrl": "https://www.youtube.com/embed/XtNb-J37RnU"
}
</script>
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Cervical Cancer | Breast Cancer | Causes, Symptoms and Treatment | Kangaroo Care",
  "description": "Dr. Veena Keerthi, Consultant - Obstetrician, and Gynecologist at Kangaroo Care - Women & Children Hospital, Mysore is here to let you know more about Cervical Cancer and Breast Cancer which are among the most frequent and fatal cancers in Women.",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/pqRZl65h6tE/mqdefault.webp",
  "uploadDate": "2021-11-19",
  "duration": "PT4M27S",  
  "contentUrl": "https://youtu.be/pqRZl65h6tE",
  "embedUrl": "https://www.youtube.com/embed/pqRZl65h6tE"
}
</script>


<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "World Prematurity Day | Prematurity Awareness | Premature Birth",
  "description": "Dr. Nanditha, Consultant - Neonatologist at Kangaroo Care - Women and Children Hospital, Mysore is here to share knowledge on premature birth.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/fu_A_LqvG_s/mq3.jpg",
  "uploadDate": "2021-11-17",
  "duration": "PT4M22S",  
  "contentUrl": "https://youtu.be/fu_A_LqvG_s",
  "embedUrl": "https://www.youtube.com/embed/fu_A_LqvG_s"
}
</script>

<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Childhood Asthma | Pediatric Asthma | Causes, Symptoms and Treatment | Kangaroo Care",
  "description": "Dr. Balaji M.D pediatrician and pediatric allergy, asthma specialist, clinical administrator at Kangaroo Care - Women & Children Hospital, Mysore is here to let you know more about childhood asthma, causes, symptoms, and treatment of pediatric asthma.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/qcya1b3mIEE/mq1.jpg",
  "uploadDate": "2021-11-16",
  "duration": "PT12M11S",  
  "contentUrl": "https://youtu.be/qcya1b3mIEE",
  "embedUrl": "https://www.youtube.com/embed/qcya1b3mIEE"
}
</script>

<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Immunization and Child Vaccination | Child Vaccination | Kangaroo Care Women & Children Hospitals",
  "description": "Dr. Balaji M.D, pediatrician and pediatric allergy, asthma specialist, clinical administrator at Kangaroo Care - Women & Children Hospital, Mysore is here to let you know the importance of immunization and vaccination for children.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/x2_kfRSbG3k/mq2.jpg",
  "uploadDate": "2021-11-16",
  "duration": "PT9M23S",  
  "contentUrl": "https://youtu.be/x2_kfRSbG3k",
  "embedUrl": "https://www.youtube.com/embed/x2_kfRSbG3k"
}
</script>
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Diarrhea in Children - by Dr. Balaji M.D | Kangaroo Care - Women & Children Hospitals",
  "description": "Dr. Balaji M.D, Pediatrician and Pediatric Allergy, Asthma Specialist, Clinical Administrator @ Kangaroo Care - Women & Children Hospital, is here to share knowledge about chronic diarrhea and acute diarrhea in infants, causes, symptoms, and treatment.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/xb3BXrxBjh4/mq2.jpg",
  "uploadDate": "2021-11-16",
  "duration": "PT9M41S",  
  "contentUrl": "https://youtu.be/xb3BXrxBjh4",
  "embedUrl": "https://www.youtube.com/embed/xb3BXrxBjh4"
}
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Sexually Transmitted Diseases | STD in Women (Kannada) | STD Causes, Symptoms and Screening",
  "description": "Dr. Leelavathi, Consultant - Obstetrician, Gynaecologist, Laparoscopic Surgeon, and Infertility Specialist at Kangaroo Care - Women & Children Hospital, is here to share more knowledge on Women reproductive health, what is STD/STI, causes of STD in Women, symptoms of infection, the preventive measures, and also about diagnostic tests available for a better life.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/YOjgN2UNRlQ/mq3.jpg",
  "uploadDate": "2021-11-16",
  "duration": "PT12M34S",  
  "contentUrl": "https://youtu.be/YOjgN2UNRlQ",
  "embedUrl": "https://www.youtube.com/embed/YOjgN2UNRlQ"
}
</script>

<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Polycystic Ovary Syndrome | PCOS | Causes, Symptoms and Treatment",
  "description": "Polycystic Ovary Syndrome (PCOS), a common endocrinological disorder in Women of reproductive age.
Here we have Dr. Leelavathi, Consultant - Obstetrician, Gynaecologist, Laparoscopic Surgeon, and Infertility Specialist at Kangaroo Care - Women & Children Hospital, to share knowledge on what is PCOS, what causes PCOS, symptoms and ways to overcome PCOS.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/6PiHwo0fq98/mqdefault.jpg",
  "uploadDate": "2021-11-12",
  "duration": "PT10M22S",  
  "contentUrl": "https://youtu.be/6PiHwo0fq98",
  "embedUrl": "https://www.youtube.com/embed/6PiHwo0fq98"
}
</script>


<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Sexually Transmitted Diseases | STD in Women | STD Causes, Symptoms and Screening",
  "description": "Dr. Leelavathi, Consultant - Obstetrician, Gynaecologist, Laparoscopic Surgeon, and Infertility Specialist at Kangaroo Care - Women & Children Hospital, is here to share more knowledge on Women reproductive health, what is STD/STI, causes of STD in Women, symptoms of infection, the preventive measures, and also about diagnostic tests available for a better life.",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/K0zhKHcEUCs/mqdefault.webp",
  "uploadDate": "2021-11-08",
  "duration": "PT8M47S",  
  "contentUrl": "https://youtu.be/K0zhKHcEUCs",
  "embedUrl": "https://www.youtube.com/embed/K0zhKHcEUCs"
}
</script>


<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Enabling COVID-19 safe environment at Kangaroo Care - Women and Children Hospitals",
  "description": "Keeping the safety of our patients as a top priority, we ensure to take all the required  measures to  enable a #covıd19 safe environment @ Kangaroo Care - Women and Children Hospital in Bangalore and Mysore",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/73G8hSUdw_A/mqdefault.webp",
  "uploadDate": "2020-07-29",
  "duration": "PT3M55S",
  "contentUrl": "https://youtu.be/73G8hSUdw_A",
  "embedUrl": "https://www.youtube.com/embed/73G8hSUdw_A"
}
</script>



<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "World Breastfeeding Week 2020 | Breastfeeding Awareness and its Importance | Kangaroo Care",
  "description": "World Breastfeeding Week - Support breastfeeding for a healthier planet  Celebrated every year in the 1st week of August,  to promote, encourage, and spread the importance of breastfeeding among young mothers all over the world.",
  "thumbnailUrl": "https://i9.ytimg.com/vi_webp/vFwiSWSfi7I/mqdefault.webp",
  "uploadDate": "2020-08-04",
  "duration": "PT16M34S",
  "contentUrl": "https://youtu.be/vFwiSWSfi7I",
  "embedUrl": "https://www.youtube.com/embed/vFwiSWSfi7I"
}
</script>
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "VideoObject",
  "name": "Antina Unde Recipe | Protein Rich Edible Gum Balls | Kangaroo Care",
  "description": "Healthy, delicious, and nutrients dense sweet balls or (antina unde in Kannada) are made with dry fruits, nuts, jaggery, and edible gum. The edible gum is very nourishing and rich in calcium, and protein. It is very beneficial for pregnant Women and lactating mothers.",
  "thumbnailUrl": "https://i9.ytimg.com/vi/kA-Pe8Fizh8/mq2.jpg",
  "uploadDate": "2020-08-06",
  "duration": "PT2M23S",  
  "contentUrl": "https://youtu.be/kA-Pe8Fizh8",
  "embedUrl": "https://www.youtube.com/embed/kA-Pe8Fizh8"
}
</script>


   `;
  // console.log(page);
  return (
    <div className="container-fluid videoTestList">
      <iframe srcDoc={blogSchemas29} title="Blog Schema" width="0" height="0" />
      <div className="row">
        {itemList.map((videoTest) => (
          <div
            key={videoTest.id}
            className="col-lg-4 col-md-4 col-xs-12 videoDiv"
          >
            <div className="player-wrapper">
              <iframe
                className="iframeClass"
                title={videoTest.title}
                src={videoTest.url}
                frameBorder="0"
              ></iframe>
              <h6 className="videoTestTitle">{videoTest.title}</h6>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoTestListPage;
