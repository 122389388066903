import React, { Fragment, Component } from "react";
import { Element, Events, scrollSpy } from "react-scroll";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import GetDocsRight from "../components/Doctors/departments/section-left";
import GetDocsLeft from "../components/Doctors/departments/section-right";
import DeptList from "../components/Doctors/departments/ramanagarDeptList";
import SEO from "../components/SEO";

class PageAbout extends Component {
  componentDidMount() {
    Events.scrollEvent.register("begin", function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      console.log("end", arguments);
    });

    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  handleSetActive(to) {
    console.log(to);
  }

  render() {
    return (
      <Fragment>
        <Header />
        <PageHeader
          bgImg={require("../assets/img/doctors-bg.jpg")}
          title="Our Doctors in Ramanagara"
          content=""
        />
        <DeptList onSetActiveFunc={this.handleSetActive} />
        <SEO />
        <Element name="obstetrics_and_gynecology" className="element">
          <GetDocsLeft
            hospitals="kcramanagar"
            deptTitle="Obstetrics and Gynecology"
            dept="obstetrics_and_gynecology"
            image="separator11.svg"
          />
        </Element>
        <Element name="paediatrics_and_subspeciatilies" className="element">
          <GetDocsRight
            hospitals="kcramanagar"
            deptTitle="Paediatrics and Subspecialities"
            dept="paediatrics_and_subspeciatilies"
            image="separator9.svg"
          />
        </Element>
        <Element name="paediatrics_and_surgeon" className="element">
          <GetDocsRight
            hospitals="kcramanagar"
            deptTitle="Paediatric Surgeon"
            dept="paediatrics_surgeon"
            image="separator9.svg"
          />
        </Element>{" "}
        <Element name="infertility-and-ivf" className="element">
          <GetDocsLeft
            hospitals="kcramanagar"
            deptTitle="Fertility & IVF"
            dept="infertility_and_ivf"
            image="separator12.svg"
          />
        </Element>
        <Element name="developmental_pediatrics" className="element">
          <GetDocsRight
            hospitals="kcramanagar"
            deptTitle="Developmental Pediatrics"
            dept="developmental_pediatrics"
            image="separator9.svg"
          />
        </Element>
        <Element name="fetal_medicine" className="element">
          <GetDocsRight
            hospitals="kcramanagar"
            deptTitle="Fetal Medicine"
            dept="fetal_medicine"
            image="separator13.svg"
          />
        </Element>
        <Element name="physicians" className="element">
          <GetDocsRight
            hospitals="kcramanagar"
            deptTitle="Physicians"
            dept="physicians"
            image="separator17.svg"
          />
        </Element>
        {/* <Element name="infertility-and-ivf" className="element">
          <GetDocsLeft
            hospitals="kcramanagar"
            deptTitle="Fertility & IVF"
            dept="infertility_and_ivf"
            image="separator12.svg"
          />
        </Element> */}
        <Element name="anaesthesia" className="element">
          <GetDocsLeft
            hospitals="kcramanagar"
            deptTitle="Anaesthesia"
            dept="anaesthesia"
            image="separator12.svg"
          />
        </Element>
        <Element name="other_specialities" className="element">
          <GetDocsLeft
            hospitals="kcramanagar"
            deptTitle="Other Specialities"
            dept="other_specialities"
            image="separator12.svg"
          />
        </Element>
        <Footer />
        <MobileMenu />
      </Fragment>
    );
  }
}

export default PageAbout;
