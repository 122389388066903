import React, { Fragment } from "react";
// import { Link } from "react-router-dom";

function Doctor(props) {
  let name = props.firstname;
  if (props.lastname && props.lastname !== ".") {
    name = name + " " + props.lastname;
  }
  const docUrl = `/doctor/${name.split(" ").join("-").toLocaleLowerCase()}/${
    props.id
  }`;

  let { isServicePage = false } = props;

  let docName = `${props.isDoctor && "DR."} ${props.firstname} ${
    props.lastname
  }`;

  let altText = `${props.isDoctor && "DR. "} ${props.firstname} ${
    props.lastname
  } ${props.designation && props.designation}`;

  const ServiceDocPage = () => {
    return (
      <Fragment>
        <div className="col-sm-6 col-lg-4 col-md-4">
          <div className="team-mem-item">
            <figure className="service-member-pic">
              <a href={`${process.env.PUBLIC_URL + docUrl}`}>
                <img
                  src={require("../../assets/img/doctors/" + props.profilePic)}
                  alt={altText}
                />
              </a>
            </figure>
            <div className="member-info">
              <h3>
                <a
                  href={`${process.env.PUBLIC_URL + docUrl}`}
                  className="stretched-link"
                >
                  {docName}
                </a>
              </h3>
              <span className="designation">{props.designation}</span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const DocPage = () => {
    return (
      <Fragment>
        {props.type === "page" ? (
          <div className="col-sm-6 col-lg-3">
            <div className="team-mem-item">
              <figure className="member-pic">
                <a href={`${process.env.PUBLIC_URL + docUrl}`}>
                  <img
                    src={require("../../assets/img/doctors/" +
                      props.profilePic)}
                    alt={altText}
                  />
                </a>
              </figure>
              <div className="member-info">
                <h5>
                  <a
                    href={`${process.env.PUBLIC_URL + docUrl}`}
                    className="stretched-link"
                  >
                    {props.isDoctor && "DR."} {props.firstname} {props.lastname}
                  </a>
                </h5>
                <span className="designation">{props.designation}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="team-mem-item">
            <figure className="member-pic">
              <a href={`${process.env.PUBLIC_URL + docUrl}`}>
                <img
                  src={require("../../assets/img/" + props.profilePic)}
                  alt={altText}
                />
              </a>
            </figure>
            <div className="member-info">
              <h5>
                <a
                  href={`${process.env.PUBLIC_URL + docUrl}`}
                  className="stretched-link"
                >
                  {props.name}
                </a>
              </h5>
              <span className="designation">{props.title}</span>
            </div>
          </div>
        )}
      </Fragment>
    );
  };

  return isServicePage == false ? <DocPage /> : <ServiceDocPage />;
}

export default Doctor;
