import React from "react";
import SectionTitle from "../../UI/SectionTitle";
import SlickSlider from "../../UI/Slick";
import Founders from "./Founders";

import teamBg from "../../../assets/img/bg-blog.jpg";
import ourJourney from "../../../assets/img/journey-new.jpg";
import founderData from "../../../data/Founders/founders.json";

function Team() {
  return (
    <div
      className="team-area-wrapper journey-area-wrapper bg-img sp-y"
      style={{ backgroundImage: `url(${teamBg})` }}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-12 text-center">
            <SectionTitle
              variant="light"
              title="Our Journey"
              heading=""
              text=""
              image={require("../../../assets/img/separator7.svg")}
              titleTag="h1"
            />
            {/* <div className="col-lg-12">
              <SlickSlider settings={settings}>
                {founderData.map((team) => (
                  <div key={team.id}>
                    <Founders
                      id={team.id}
                      text={team.text}
                      image={team.image}
                      name={team.name}
                      link={team.link}
                    />
                  </div>
                ))}
              </SlickSlider>
            </div> */}

            <div className="founder-item-container">
              <img
                src={ourJourney}
                alt="Our Journey | Kangaroo Care Hospitals"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
