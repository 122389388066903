import React from "react";
import From from "./From";
import ContactInfo from "./ContactInfo";
import BreadCrumb from "../../components/BreadCrumb";

const ContactPage = () => {
  return (
    <div className={"contact-page-area-wrapper sp-y"}>
      <BreadCrumb />
      <div className="container">
        <div className="contact-content-wrap">
          <div className="row">
            <div className="col-lg-8">
              <div className="contact-form-area contact-method">
                <h1>Contact Us</h1>
                <h3>Send us a Message</h3>
                <div className="contact-form-item">
                  <From />
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="contact-information contact-method">
                <div className="contact-info-con">
                  <p>
                    At Kangaroo Care, we offer round-the-clock Paediatric OBG
                    critical care & more. We also provide hospital-on-wheels
                    with KATS -Kangaroocare Acute Emergency Transport System. If
                    you have any queries, feel free to write to us or call us at
                    these numbers.
                  </p>
                  <br />
                  <h4>Bangalore Contact Info</h4>
                  {/* <ContactInfo address="<p>#89, 17th cross, MC layout, Near Balagangadharnath Swamy Metro Station, Vijayanagar, Bangalore - 560040</p> <i class='fa fa-envelope contact-icon-w'></i> info@kangaroocareindia.com <br/><i class='fa fa-phone contact-icon-w'></i>+91 80-23015678 <br/><i class='fa fa-phone contact-icon-w'></i>+91 8296688887" /> */}
                  {/* <ContactInfo
                    address="<p>#505, Kalidasa road, Vijayanagar 1st stage, Opp. MUDA Complex, Mysore - 570017</p> <i class='fa fa-phone contact-icon-w'></i>Toll Free : &nbsp;1800-425-4500 <br/>"
                    social={true}
                  /> */}
                  <ContactInfo address="<p>#89, 17th cross, MC layout, Near Balagangadharnath Swamy Metro Station, Vijayanagar, Bangalore - 560040</p> <i class='fa fa-phone contact-icon-w'></i>Toll Free : &nbsp;1800-425-4500 <br/><br/>" />
                  <h4>Mysore Contact Info</h4>
                  <ContactInfo address="<p>#505, Kalidasa road, Vijayanagar 1st stage, Opp. MUDA Complex, Mysore - 570017</p> <i class='fa fa-phone contact-icon-w'></i>Toll Free : &nbsp;1800-425-4500 <br/>" />
                  <br />
                  <h4>Ramanagara Contact Info</h4>
                  <ContactInfo address="<p>No.32 of Vivekanandanagar, Block-3, North B.M. Road, Ramanagara -562 159 Karnataka</p> <i class='fa fa-phone contact-icon-w'></i>Toll Free : &nbsp;1800-425-4500 <br/>" />
                  <br />

                  <h4>Nagarabhavi Clinic Contact Info</h4>
                  <ContactInfo address="<p>#236, 4th cross, RHCS layout, Annapoorneshwari Nagar Main road , Nagarabhavi Bangalore - 560091</p> <i class='fa fa-phone contact-icon-w'></i>Toll Free : &nbsp;1800-425-4500 <br/> " />
                  <br />
                  <h4>Banashankari Clinic Contact Info</h4>
                  <ContactInfo address="<p>#23, Katriguppa Main Road, Opp. Big Bazar, B.S.K. 3rd Stage, Bangalore-85</p> <i class='fa fa-phone contact-icon-w'></i>Toll Free : &nbsp;1800-425-4500 <br/> " />
                  <br />
                  <h4>Kangaroo Care Dentistry Contact Info</h4>
                  <ContactInfo address="<p>#119, 8th cross MC Layout,Vijaynagar, Bangalore</p> <i class='fa fa-phone contact-icon-w'></i>Toll Free : &nbsp;1800-425-4500 <br/> " />

                  <br />
                  <h4>Kangaroo Care Kids</h4>
                  <ContactInfo address="<p>#119, 8th cross MC Layout,Vijaynagar, Bangalore</p> <i class='fa fa-phone contact-icon-w'></i>Toll Free : &nbsp;1800-425-4500 <br/> " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
