import React from "react";
import { useState } from "react";
//styles
import styles from "./pop.module.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { useEffect } from "react";
export default function Popup() {
  const [show, setShow] = useState(false);

  const [form, setForm] = useState({
    fname: "",
    email: "",
    phone: "",
  });
  const handleChange = (e) => {
    let { name, value } = e.target;

    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      setTimeout(() => {
        setShow(true);
      }, 1000);
    }
  }, []);

  const handleActiveClick = () => {
    setShow(!show);
  };

  return (
    <section
      className={`${styles.pop_up_container} ${
        show && styles.pop_up_container_active
      }`}
    >
      <div className={styles.pop_up_bg}></div>
      <div
        className={`${styles.pop_up_width}   ${
          show && styles.pop_up_form_show
        }`}
      >
        <div className={styles.pop_up_title}>
          <h3>Get In Touch</h3>
          <AiFillCloseCircle onClick={() => handleActiveClick()} />
          <p>{form.fname.length > 1 && `Hi ${form.fname}.`}</p>
        </div>

        <form
          className={`${styles.pop_up_form}`}
          method="POST"
          action="https://mail.digikit.in/forms/kangaroocare/pop.php"
        >
          <div>
            <input
              type="text"
              name="fname"
              placeholder="Name"
              value={form.fname}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={form.email}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={form.phone}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div>
            <input type="submit" value="Submit" />
          </div>
        </form>
      </div>
    </section>
  );
}
