import React from "react";
// import { Link } from "react-router-dom";
import navbarData from "../../../data/Navbar/navbar.json";

function NavbarItem(props) {
  return navbarData.map((item) => (
    <li
      key={item.id}
      className={item.subMenu || item.megaMenu ? "has-submenu" : ""}
    >
      <a href={`${process.env.PUBLIC_URL + item.link}`}>{item.title}</a>
      {(() => {
        if (item.subMenu) {
          return (
            <ul className="submenu-nav">
              {item.subMenu.map((subItem, index) => {
                return (
                  <li
                    key={index}
                    className={
                      item.megaMenu || item.subMegaMenu
                        ? "extra-submenuDisplay"
                        : ""
                    }
                  >
                    <a href={`${process.env.PUBLIC_URL + subItem.link}`}>
                      {subItem.title}
                    </a>

                    {/* <ul className="extra-submenu" > */}
                    {subItem.subMegaMenu === true && (
                      <ul className="extra-submenu">
                        {subItem.subMegaMenu === true &&
                          subItem.extraSubMenu.map((value, index) => {
                            return (
                              <>
                                <li key={index} className="">
                                  <a
                                    href={`${
                                      value.redirectLink
                                        ? value.redirectLink
                                        : process.env.PUBLIC_URL + value.link
                                    }`}
                                  >
                                    {value.title}
                                  </a>
                                </li>
                              </>
                            );
                          })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          );
        }

        if (item.megaMenu) {
          return (
            <ul className="submenu-nav submenu-nav-mega">
              {item.megaMenu.map((megaItem, indx) => (
                <li key={indx} className="mega-menu-item">
                  <a href={megaItem.link}>{megaItem.title}</a>
                  <ul>
                    {megaItem.lists.map((listItem, idx) => {
                      return (
                        <li key={idx}>
                          <a href={`${process.env.PUBLIC_URL + listItem.link}`}>
                            {listItem.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          );
        }
      })()}
    </li>
  ));
}

export default NavbarItem;
