import React, { Fragment } from "react";
import PageHeader from "../../components/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ServiceContentWrap from "./ServiceContentWrap";
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import Testimonials from "../../components/Testimonials/centers";
import Form from "./Form";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import { Link } from "react-router-dom";
import Anchor from "../../components/UI/Anchor";

import CentresList from "../../data/Centres/allCentres.json";

const ServiceDetails = () => {
  //  FIND PATH NAME
  let { pathname } = window.location;
  let pathnameArr = pathname.split("/");

  if (pathnameArr[2] === undefined) {
    //  REDIRECT TO ERR PAGE
  }

  //  console.log(pathname);

  const centreData = CentresList.find(
    (centre) => centre.urlIndex === pathnameArr[2]
  );

  // console.log(centreData);
  if (centreData !== undefined) {
    //  REDIRECT TO ERR PAGE
  }

  let kangaroocareFertilitytestlinkOurCenter = "none";
  let testlinkOurCenter = window.location.href;
  let testlinkOurCenters = testlinkOurCenter
    .split("/")
    .slice(3)
    .join("/")
    .replace("#", "");
  if (testlinkOurCenters == "centre/fertility-center") {
    kangaroocareFertilitytestlinkOurCenter = "block";
  }

  // if(window.location.href=="")
  return (
    <Fragment>
      <PageHeader
        bgImg={require(`../../assets/img/${centreData.bgImg}`)}
        pathname={pathname}
      />
      {/* <p
        id="kangaroo-fertility"
        style={{ display: kangaroocareFertilitytestlinkOurCenter }}
      >
        click here to view the detail website of &nbsp; &nbsp; &nbsp;
        <a
          href="http://kangaroocarefertility.com/"
          className="tel-no kangaroocare-fertility"
          title="KangarooCare Fertility"
          target="_blank"
        >
          Kangaroocare Fertility
        </a>
      </p> */}

      <PageWrapper classes="sm-top service-details-wrapper bs100">
        <ServiceContentWrap service={centreData} />

        <Sidebar classes={"col-lg-4 "}>
          {centreData["360View"] && (
            <iframe
              src={centreData["360View"]}
              frameborder="0"
              width="100%"
              height="200px"
              scrolling="no"
              allow="vr,gyroscope,accelerometer"
              allowfullscreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
            ></iframe>
          )}

          <SidebarItem title="Our Centres">
            <List classes="service-list">
              {CentresList.map((centreItem) => (
                <LI key={centreItem.id}>
                  <a
                    href={`${
                      centreItem.urlIndex === ""
                        ? "https://kangaroocareindia.com/"
                        : process.env.PUBLIC_URL +
                          `/centre/${centreItem.urlIndex}`
                    }`}
                  >
                    {centreItem.title}
                  </a>
                </LI>
              ))}
            </List>
          </SidebarItem>
          <SidebarItem title="GET A CALLBACK">
            <List classes="service-list">
              <form
                className="center_contact_form"
                method="POST"
                action="https://mail.digikit.in/forms/kangaroocare/center.php"
              >
                <input type="hidden" value={window.location.href} name="url" />
                <div>
                  <input type="text" placeholder="Name" name="name" required />
                </div>
                <div>
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    name="phone"
                    pattern="^[6-9]\d{9}$"
                    required
                  />
                </div>
                <div>
                  <textarea name="message" id="message" placeholder="Query" />
                </div>
                <div>
                  <input type="submit" value="Submit" />
                </div>
              </form>
            </List>
          </SidebarItem>
          {/* 
          <SidebarItem title={centreData.contactText}>
            <List classes="service-list">
              <LI>
                <div className="container" style={{ textAlign: "center" }}>
                  <Link to={"/book-appointment"} style={{ margin: "0 auto" }}>
                    <button className="book-appointment">
                      Book Appointment
                    </button>
                  </Link>
                </div>
              </LI>
              <LI>
                <Anchor path="/contact">
                  <i className="fa fa-map-marker contact-icon" />
               
                  {centreData.address}
                </Anchor>
              </LI>
              <LI>
                <Anchor path="/contact">
                  <i className="fa fa-phone-square contact-icon" />
                  {centreData.toolfree}
                </Anchor>
              </LI>
              <LI>
                <Anchor path="/contact">
                  <i className="fa fa-envelope contact-icon" />
                  {centreData.email}
                </Anchor>
              </LI>
            </List>
          </SidebarItem> */}
        </Sidebar>
      </PageWrapper>
      {centreData?.testimonials && (
        <Testimonials testimonial={centreData.testimonials} />
      )}
    </Fragment>
  );
};

export default ServiceDetails;
