import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
// import GetDocsRight from "../components/Doctors/departments/section-left";
import GetDocsLeft from "../components/Doctors/departments/section-right";
import SEO from "../components/SEO";

const PageAbout = (props) => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/doctors-bg.jpg")}
        title="Doctors in our Clinics"
        content=""
      />
      <SEO />
      <GetDocsLeft
        hospitals="kcclinicsbanashankari"
        deptTitle="Paediatrics and Subspecialities"
        dept="paediatrics_and_subspeciatilies"
        image="separator9.svg"
      />

      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PageAbout;
