import React from "react";
import SectionTitle from "../UI/SectionTitle";
import DownloadItem from "./PackageDetailsMediaItems";
import downloadData from "../../data/Downloads/downloads";
import downloadDatas from "../../data/PackageDetailsMediaFiles/packagesItems";
import serviceTopBg from "../../assets/img/service/service-bg-1.jpg";
import package1 from "../../assets/img/media/packageimage/package-01.jpg";
import package2 from "../../assets/img/media/packageimage/package-02.jpg";
import pcospackage from "../../assets/img/media/packageimage/package-pcos.jpg";
import menpackage from "../../assets/img/media/packageimage/package-03-men-fertility-care.jpg";
import womenpackage from "../../assets/img/media/packageimage/package-04-women-fertility-care.jpg";
import womenhealthpackage from "../../assets/img/media/packageimage/package-women-health.jpg";
import womendiabeticpackage from "../../assets/img/media/packageimage/women-diabetic.jpg";
import womenquickscreening from "../../assets/img/media/packageimage/women-quickscreening.jpg";
import childadolescent from "../../assets/img/media/packageimage/package-10.jpg";
import childpackage from "../../assets/img/media/packageimage/child-2.jpg";
import childadvanced from "../../assets/img/media/packageimage/child-3.jpg";
import obesity from "../../assets/img/media/packageimage/obesity-package.jpg";

import { Link } from "react-scroll";
function Services() {
  return (
    <>
      <div className="container">
        <div className="row deptListDiv">
          <div className="card cardStyle m-2  m-md-3">
            <Link to="pcospack" offset={-300} smooth={true}>
              <div className="card-body">
                <h5 className="card-title cardTitle ">WOMEN HEALTH CHECK</h5>
              </div>
            </Link>
          </div>
          <div className="card cardStyle m-2 m-md-3">
            <Link to="fertilitycareboth" offset={-400} smooth={true}>
              <div className="card-body">
                <h5 className="card-title cardTitle">
                  FERTILITY HEALTH CHECK FOR COUPLES
                </h5>
              </div>
            </Link>
          </div>{" "}
          <div className="card cardStyle m-2 m-md-3">
            <Link to="childhealthcare" offset={-400} smooth={true}>
              <div className="card-body">
                <h5 className="card-title cardTitle">CHILD HEALTH CHECKUP</h5>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className={`service-area-wrapper`}>
        <div
          className="service-area-top"
          style={{ backgroundImage: `url("${serviceTopBg}")` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-xl-8 m-auto text-center">
                <SectionTitle
                  variant="light"
                  image={require("../../assets/img/separator20.svg")}
                  title="Health Check Package Details"
                  heading=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="service-content-area">
          <div className="container">
            <div className="row mtn-30"> */}
        {/* {downloadDatas.map((dl) => (
              <DownloadItem
                key={dl.id}
                id={dl.id}
                title={dl.title}
                file={dl.file}
                image={dl.image}
              />
            ))} */}
        {/* <div className="col-sm-6 col-lg-6">
                <div className="service-item">
                  <figure className="service-thumb">
                    <img src={package1} alt="" />
                    <figcaption className="service-txt">
                      <h5 className="text-center" style={{ fontWeight: "500" }}>
                        PCOS Check Up
                      </h5>
                    </figcaption>
                    <div className="package-list-items-title ml-3 mt-2">
                      <h3 className="text-left">Symptoms</h3>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-sm-6 col-lg-6 text-left package-list-items-list">
                          <ul>
                            <li>Irregular periods</li>
                            <li>Weight gain & mood change</li>
                            <li>Infertility</li>
                            <li>Acne, oily skin, hair loss</li>
                            <li>Unwanted hair growth</li>
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-20 mb-10">
                        <div className="package-list-items-price">
                          <p>
                            <span className="package-list-items-price-list">
                              Rs: 3600/-
                            </span>
                            <span style={{ marginLeft: "5px" }}>
                              Rs: 2999/- only
                            </span>
                          </p>
                        </div>
                        <div className="package-list-items-button ">
                          <a href="tel:080-23015678">
                            <button classname="btn btn-primary" type="button">
                              Call Now
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>
              <div className="col-sm-6 col-lg-6">
                <div className="service-item">
                  <figure className="service-thumb">
                    <img src={package2} alt="" />
                    <figcaption className="service-txt">
                      <h5 className="text-center" style={{ fontWeight: "500" }}>
                        Senior Women Health Check
                      </h5>
                    </figcaption>
                    <div className="package-list-items-title ml-3 mt-2">
                      <h3 className="text-left">Tests</h3>
                    </div>

                    <div className="container ">
                      <div className="row">
                        <div className="col-sm-6 col-lg-6 text-left package-list-items-list">
                          <ul>
                            <li>Urine Routine Analysis</li>
                            <li>ECG (Resting)</li>
                            <li>Breast Screening </li>
                            <li>Ultrasonogram Abdomen & Pelvic</li>
                            <li>Pap Smear Cytology</li>
                          </ul>
                        </div>
                        <div className="col-sm-6 col-lg-6 text-left package-list-items-list">
                          <ul>
                            <li>CBC</li>
                            <li>Thyroid Stimulating Hormone</li>
                            <li>Fasting Blood Sugar</li>
                            <li>Creatinine</li>
                            <li>Serum Calcium</li>
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-20 mb-10">
                        <div className="package-list-items-price">
                          <p>
                            <span className="package-list-items-price-list">
                              Rs: 6999/-
                            </span>
                            <span style={{ marginLeft: "5px" }}>
                              Rs: 5800/- only
                            </span>
                          </p>
                        </div>
                        <div className="package-list-items-button ">
                          <a href="tel:080-23015678">
                            <button classname="btn btn-primary" type="button">
                              Call Now
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <h3
          id="pcospack"
          className=" text-center mb-5 mt-md-3 mb-md-5 mt-4  package-titles "
        >
          WOMEN HEALTH CHECK
        </h3>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="m-2 m-md-">
              <h3 className=" text-center mt-md-1 mb-md-5 package-title">
                PCOS IN TEENS & YOUNG WOMEN
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6 mb-sm-0 mb-3">
                    <img src={pcospackage} alt="PCOS IN TEENS & YOUNG WOMEN" />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>FBS</li>
                            <li>FSH</li>
                            <li>LH</li>
                            <li>Prolactin</li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>TSH</li>
                            <li>Pelvic Scan</li>
                            <li>Gynaecology Consultation</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs">
                    <p>
                      About 25% of Women are affected with PCOS. But not all
                      Women know that they have PCOS because of its varied
                      presentation.
                    </p>
                    <p>
                      <strong>
                        So, what is PCOS? | What causes PCOS? | Does it affect
                        our life?
                      </strong>
                    </p>
                    <p>
                      Well, PCOS stands for polycystic ovarian syndrome. PCOS
                      causes irregular menstrual cycles, excessive body or
                      facial hair & polycystic ovaries as its main symptoms.
                    </p>

                    <p>
                      Polycystic means "many cysts," and PCOS ofen causes
                      clusters of small, pearl-sized cysts in the ovaries. The
                      cysts are fluid-filled & contain immature eggs.
                    </p>

                    <p>
                      Women with PCOS produce slightly higher amounts of male
                      hormones known as androgens, which contribute to some of
                      the symptoms of the condition like irregular periods, mood
                      swings, weight, gain acne, oily skin, hair loss,
                      infertility etc.
                    </p>

                    <p>
                      Long term complications such as Diabetes Mellitus,
                      Cardiovascular diseases, Endometrial cancer are also
                      known. It can be treated & you can begin a new life.
                    </p>
                  </div>
                </div>
                {/* <div className="d-flex mt-5 mb-10 justify-content-center  align-items-end">
                  <div className="package-list-items-price">
                    <p>
                      <span className="package-list-items-price-list">
                        Rs: 3600/-
                      </span>
                      <span style={{ marginLeft: "5px" }}>Rs: 2999/- only</span>
                    </p>
                  </div>
                  <div className="package-list-items-button ">
                    <a href="tel:080-23015678">
                      <button classname="btn btn-primary" type="button">
                        Call Now
                      </button>
                    </a>
                    <br />
                    <a href="tel:080-23015678">
                      <button classname="btn btn-primary" type="button">
                        Call Now
                      </button>
                    </a>
                  </div>
                </div> */}
                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 3600/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 2999/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row justify-content-center align-items-center "
            id="wellwomen"
          >
            <div className=" m-2  m-md-5">
              <h3 className="text-center mt-md-3  mb-md-5 package-title">
                WELL WOMEN HEALTH CHECK
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <img
                      src={womenhealthpackage}
                      alt="WELL WOMEN HEALTH CHECK"
                    />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Gynaecology Consultation</li>
                            <li>Fasting Blood Sugar</li>
                            <li>Post Prandial Blood Sugar</li>
                            <li>Thyroid Stimulating Hormone</li>
                            <li>Creatinine</li>
                            <li>Pap Smear Cytology</li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>ECG</li>
                            <li>USG - Abdomen & Pelvic Scan</li>
                            <li>Urine Routine Analysis</li>
                            <li>Dietician Consultation</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs">
                    <p>
                      Every woman has the right to enjoy highest possible
                      standards of mental and physical health.
                    </p>
                    <p>
                      For a woman, their health involves physical, social and
                      emotional well-being, & our political, social, economic
                      and biological factors play a major role in determining a
                      woman’s health. Unfortunately, a majority of Women around
                      the world could rarely practice their right to health and
                      wellbeing, due to their busy schedules, work, daily
                      chores, family, the list never ends.
                    </p>
                    <p>
                      Once the age increases, so do the medical disorders. So,
                      we have to be always on the look out and pick up these
                      early signs of medical disturbances, which will disturb
                      our good quality of living.
                    </p>

                    <p>
                      Medical disturbances which are seen more ofen when the age
                      increases- Diabetes, Heart Diseases, Osteoporosis, Cancer.
                    </p>

                    <p>
                      Get regular check-ups, and follow some proactive simple
                      steps toward a longer, healthier life.
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 3900/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 3250/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row justify-content-center align-items-center "
            id="srwellwomen"
          >
            <div className=" m-2  m-md-5">
              <h3 className="text-center mt-md-1    mb-md-5 package-title">
                SR. WOMEN WELLNESS CLINIC
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <img src={package2} alt="SR. WOMEN WELLNESS CLINIC" />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Gynaecology Consultation</li>
                            <li>ECG (Resting)</li>
                            <li>Breast ultrasound screening</li>
                            <li>USG - Abdomen & Pelvic Scan</li>
                            <li>Pap Smear Cytology</li>
                            <li>CBC</li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Thyroid Stimulating Hormone (TSH)</li>
                            <li>Fasting Blood Sugar</li>
                            <li>Creatinine</li>
                            <li>Serum Calcium</li>
                            <li>Vitamin D Total</li>
                            <li>Urine Routine Analysis</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs align-middle">
                    <p>
                      Ageing gracefully is the Mantra of a great life! It is not
                      always necessary that as you age, you’ll have a slew of
                      health problems, but it is important to know your body &
                      mind, balance them to accept the continuous change. A
                      positive & a cheerful attitude towards life and regular
                      monitoring your physical & mental health is the Mantra for
                      Ageing Gracefully!
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 6999/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 5800/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row justify-content-center align-items-center "
            id="srwellwomen"
          >
            <div className=" m-2  m-md-5">
              <h3 className="text-center mt-md-1    mb-md-5 package-title">
                WOMEN DIABETIC HEALTH CHECK
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <img
                      src={womendiabeticpackage}
                      alt="WOMEN DIABETIC HEALTH CHECK"
                    />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>CBC with ESR</li>
                            <li>Fasting blood sugar</li>
                            <li>Postprandial blood sugar</li>
                            <li>Lipid profile</li>
                            <li>HbA1c</li>
                            <li>Thyroid profile</li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Liver function test</li>
                            <li>Renal function test</li>
                            <li>Urine Routine</li>
                            <li>ECG</li>
                            <li>Consultation with Diabetologist</li>
                            <li>Consultation with Dietician</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs align-middle">
                    <p>
                      Diabetes is a disease where the blood glucose or blood
                      sugar level is too high. Blood glucose, which is the main
                      source of energy, comes from the food that you eat. The
                      human body produces a hormone called insulin used to
                      digest food and helps glucose from the food to get into
                      the cells. When your body produces less insulin, glucose
                      stays in your body and doesn’t get into the cells.
                    </p>
                    <p>
                      Anyone can get diabetes irrespective of age or gender.{" "}
                    </p>
                    <p>
                      Some of the common symptoms of Women having diabetes are
                      vaginal infection, vaginal thrush, urinary infections,
                      sexual dysfunction, and PCOS. Uncontrolled diabetes can
                      also result in birth defects.{" "}
                    </p>
                    <p>
                      Although diabetes is a life-threatening condition, it can
                      be effectively treated and managed with various
                      treatments. Women can get their diabetes health checkups
                      done to prevent the complications that can happen in the
                      future.
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 5500/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 4399/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row justify-content-center align-items-center "
            id="srwellwomen"
          >
            <div className=" m-2  m-md-5">
              <h3 className="text-center mt-md-1    mb-md-5 package-title">
                WOMEN HEALTH QUICK SCREENING:
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <img
                      src={womenquickscreening}
                      alt="WOMEN HEALTH QUICK SCREENING"
                    />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Fasting Blood Sugar</li>
                            <li>CBC</li>
                            <li>Thyroid-stimulating hormone (TSH)</li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>ECG</li>
                            <li>Urine Routine Analysis</li>
                            <li>Consultation with Gynecologist</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs align-middle">
                    <p>
                      Women are known as multitaskers and they are seen working
                      without rest at home or office. In the midst of the
                      responsibilities of home, children, and work, they neglect
                      their health. Women must be proactive about their own
                      needs and focus on their health and medical needs. Health
                      checkups are important for everyone to help diagnose
                      diseases and prevent the diseases from getting worse.
                      There are various Women's health checkups depending on
                      age, family medical history, and other specific risk
                      factors.
                    </p>
                    <p>Early Detection Saves Lives..!</p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 1600/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 1200/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 mt-md-5" id="fertilitycareboth"></div>
        <h3 className=" text-center mt-4 mt-md-5 mb-md-5 mb-5  package-titles">
          FERTILITY HEALTH CHECK FOR COUPLES
        </h3>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="m-2 m-md-2">
              <h3 className=" text-center mt-md-3 mb-md-5 package-title">
                FERTILITY CARE FOR HER
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6 mb-sm-0 mb-3">
                    <img src={womenpackage} alt="FERTILITY CARE FOR HER" />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Gynaecology consultation</li>
                            <li>Baseline Pelvic Scan</li>
                            <li>FSH</li>
                            <li>LH</li>
                            <li>AMH</li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>TSH</li>
                            <li>Prolactin</li>
                            <li>RBS</li>
                            <li>Urine Routine Analysis</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs">
                    <p>
                      The fertility specialists at Kangaroo Care help you in
                      determining the safe ways of planning your family. While
                      we are planning for pregnancy, it is necessary for both
                      partners to go for fertility testing. If they'd like to be
                      proactive in bringing in new additions to their family or
                      are having trouble conceiving, this fertility check-up is
                      helpful to such a couple
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 6200/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 4999/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center align-items-center ">
            <div className=" m-2  m-md-5">
              <h3 className="text-center mt-md-5  mb-md-5 package-title">
                FERTILITY CARE FOR HIM
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <img src={menpackage} alt="FERTILITY CARE FOR HIM" />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <ul>
                              <li>Consultation</li>
                              <li>Semen Analysis</li>
                            </ul>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>TSH</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs align-middle">
                    <p>
                      The fertility specialists at Kangaroo Care help you in
                      determining the safe ways of planning your family. While
                      you are planning for pregnancy, it is necessary for both
                      partners to go for fertility testing. If they'd like to be
                      proactive in bringing in new additions to their family or
                      are having trouble conceiving, this fertility check-up is
                      helpful to such couple.
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 1800/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 1500/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 mt-md-5" id="childhealthcare"></div>
        <h3 className=" text-center mt-4 mt-md-5 mb-md-5 mb-5  package-titles">
          CHILD HEALTH CHECKUP
        </h3>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="m-2 m-md-2">
              <h3 className=" text-center mt-md-1 mb-md-5 package-title">
                KID’S WELLNESS HEALTH CHECK-UP
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6 mb-sm-0 mb-3">
                    <img
                      src={childpackage}
                      alt="KID’S WELLNESS HEALTH CHECK-UP"
                    />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>HB - Hemoglobin</li>
                            <li>Random Blood Sugar (RBS)</li>
                            <li>Ophthalmology Consultation</li>
                            <li>Dental Checkup</li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Stool microscopy</li>
                            <li>Urine Routine</li>
                            <li>Nutrition Assessment</li>
                            <li>
                              Pediatric Consultation - Children growth &
                              development assessment
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs">
                    <p>
                      Every kid needs special care for healthy development. A
                      child’s health is a very important factor for every
                      parent. Proper child care results in the better growth and
                      development of the child. In other words, children who
                      benefit from proper medical care have good health and
                      continue being healthy in the future.
                    </p>
                    <p>
                      However, every child has specific needs and has various
                      health risks at all stages of physical and mental
                      development. It's better to take your child for a regular
                      health checkup once in a while for a better & healthy
                      life.
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 2100/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 1699/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 mt-md-5" id="childhealthcare"></div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="m-2 m-md-2">
              <h3 className=" text-center mt-md-1 mb-md-5 package-title">
                ADOLESCENT HEALTH CHECK-UP
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6 mb-sm-0 mb-3">
                    <img
                      src={childadolescent}
                      alt="ADOLESCENT HEALTH CHECK-UP"
                    />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Blood Group & RH Typing</li>
                            <li>CBC with peripheral smear</li>
                            <li>Random Blood Sugar (RBS)</li>
                            <li>Thyroid Stimulating Hormones</li>
                            <li>Liver Function Test</li>
                            <li>Renal Function Test</li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>
                              Bone profile (Calcium, Phosphorous, and ALP)
                            </li>
                            <li>Ferritin</li>
                            <li>Urine Routine Analysis</li>
                            <li>Stool microscopy</li>
                            <li>
                              Pediatric Consultation/growth and nutritional
                              assessment/SMR assessment
                            </li>
                            <li>Dietician assessment</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs">
                    <p>
                      Adolescence is a phase of life between 10 to 19 years of
                      age, i.e from childhood to adulthood. It is also an
                      important period to promote health awareness and prevent
                      disease.
                    </p>
                    <p>
                      Adolescent health involves the development of physical,
                      social, emotional, cognitive, and intellectual health. The
                      rapid development of these various areas could lead to
                      phenomenal growth during this period. This growth can also
                      occur at different rates, impacting their physical &
                      mental health. A preventive health checkup can give you
                      indications about possible health issues so you can get
                      the necessary treatment immediately and avoid future
                      complications.
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 5000/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 3999/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 mt-md-5" id="childhealthcare"></div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="m-2 m-md-2">
              <h3 className=" text-center mt-md-1 mb-md-5 package-title">
                ADVANCED HEALTH CHECK-UP
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6 mb-sm-0 mb-3">
                    <img src={childadvanced} alt="ADVANCED HEALTH CHECK-UP" />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>RBS</li>
                            <li>CBC with peripheral smear</li>
                            <li>Renal Function Test</li>
                            <li>Liver Function Test</li>
                            <li>Vitamin D</li>
                            <li>Lipid profile</li>
                            <li>TSH, T4</li>
                            <li>
                              Bone profile (Calcium, Phosphorous, and ALP)
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Stool microscopy</li>
                            <li>Urine Routine Analysis</li>
                            <li>Pediatric Consultation/growth assessment</li>
                            <li>Ophthalmology Consultation</li>
                            <li>Dietician assessment</li>
                            <li>Dental Check-up</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs">
                    <p>
                      Children are very smart hence, parents must ensure, they
                      maximize their child’s abilities by providing them with
                      every opportunity to enhance their potentials.
                    </p>
                    <p>
                      At Kangaroo Care, we provide the following tests. Find the
                      right health pack for your child.
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 7800/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 6299/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 mt-md-5" id="childhealthcare"></div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="m-2 m-md-2">
              <h3 className=" text-center mt-md-1 mb-md-5 package-title">
                OBESITY
              </h3>
              <div className="container mt-3 ">
                <div className="row">
                  <div className="col-12 col-md-6 mb-sm-0 mb-3">
                    <img src={obesity} alt="OBESITY" />
                    <div className="container package-paragraphs-list">
                      <div className="row mt-2 mt-md-4">
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Fasting Blood Sugar</li>
                            <li>Postprandial Blood Sugar</li>
                            <li>HbA1C</li>
                            <li>Complete Blood Count</li>
                            <li>Liver Function test</li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          <ul>
                            <li>Lipid Profile</li>
                            <li>Free T4, TSH</li>
                            <li>Serum cortisol (Morning)</li>
                            <li>Renal function test</li>
                            <li>Consultation with Endocrionologist</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  package-paragraphs">
                    <p>
                      {/* Obesity can lead to - <br /> <br />
                      <ul style={{ marginLeft: "3%" }}>
                        <li>High Blood Pressure</li>
                        <li> Type 2 Diabetes</li>
                        <li> High Cholesterol</li>
                        <li>Heart Diseases</li>
                      </ul> */}
                      Obesity has root causes throughout society. Tackling the
                      causes of obesity benefits everyone and we all get a
                      chance to lead healthier and longer life. Obesity is a
                      major public health challenge faced globally. Obesity can
                      lead to High Blood Pressure, Type 2 Diabetes, High
                      Cholesterol, Heart Diseases. At Kangaroo Care, we provide
                      the following tests.
                    </p>
                  </div>
                </div>

                <div className="container">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-price d-flex justify-content-center align-items-center  justify-content-md-end align-items-md-center ">
                        <p>
                          <span className="package-list-items-price-list">
                            Rs: 5300/-
                          </span>
                          <span style={{ marginLeft: "5px" }}>
                            Rs: 4300/- only
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-6 ">
                      <div className="package-list-items-button d-flex justify-content-center align-items-center justify-content-md-start align-items-md-center  ">
                        <a href="tel:1800-425-4500">
                          <button classname="btn btn-primary" type="button">
                            Call Now
                          </button>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-4">
                      <div className="package-list-items-button"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
